<template>
  <vx-card title="Sales Target">
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <div class="vx-input-group flex">
          <vs-button
            v-if="hasPermission('create')"
            class="mt-2 mr-2"
            color="success"
            type="border"
            icon-pack="feather"
            icon="icon-plus"
            @click="handleCreate()"
            >Create</vs-button
          >
          <vs-button
            v-if="hasPermission('create')"
            class="mt-2"
            color="success"
            type="border"
            icon-pack="feather"
            icon="icon-upload"
            @click="handleImport()"
            >Import</vs-button
          >
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="true"
          :options="optionTerritory"
          v-model="selectedTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Target Year</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="year"
          format="yyyy"
          :inline="false"
          v-model="selectedTargetYear"
          minimum-view="year"
        >
        </datepicker>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Target Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :filterable="true"
          :options="optionTargetType"
          v-model="selectedTergetType"
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Attribute Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select multiple :filterable="true" :options="optionAttributeType" v-model="selectedAttributeType" />
      </div>
    </div> -->
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vs-tabs :value="active">
          <vs-tab label="Draft">
            <div class="tab-text">
              <!-- draft here -->
              <data-table
                :territories="selectedTerritory"
                :target-types="selectedTergetType"
                :attribute-types="selectedAttributeType"
                :target-year="selectedTargetYear"
                :baseUrl="this.baseUrl"
                status="Draft"
              ></data-table>
            </div>
          </vs-tab>
          <vs-tab label="Waiting Approval">
            <div class="tab-text">
              <!-- pending here -->
              <data-table
                :territories="selectedTerritory"
                :target-types="selectedTergetType"
                :attribute-types="selectedAttributeType"
                :target-year="selectedTargetYear"
                :baseUrl="this.baseUrl"
                status="Waiting to Approve"
              ></data-table>
            </div>
          </vs-tab>
          <vs-tab label="Approved">
            <div class="tab-text">
              <!-- approved here -->
              <data-table
                :territories="selectedTerritory"
                :target-types="selectedTergetType"
                :attribute-types="selectedAttributeType"
                :target-year="selectedTargetYear"
                :baseUrl="this.baseUrl"
                status="Approved"
              ></data-table>
            </div>
          </vs-tab>
          <vs-tab label="Rejected">
            <div class="tab-text">
              <!-- active here -->
              <data-table
                :territories="selectedTerritory"
                :target-types="selectedTergetType"
                :attribute-types="selectedAttributeType"
                :target-year="selectedTargetYear"
                :baseUrl="this.baseUrl"
                status="Rejected"
              ></data-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";

import vSelect from "vue-select";
export default {
  components: {
    DataTable,
    vSelect,
    Datepicker,
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/sales-target",
      optionTerritory: [],
      selectedTerritory: [],
      selectedTargetYear: null,
      optionTargetType: ["Monthly", "Weekly"],
      selectedTergetType: [],
      optionAttributeType: [
        "General",
        "Principal",
        "Brand",
        "Product Category",
        "Distribution Channel",
        "Sales Channel",
      ],
      selectedAttributeType: [],
    };
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "sales-target-create",
        // params: { id: id },
      });
    },
    handleImport() {
      this.$router.push({
        name: "sales-target-import",
        // params: { id: id },
      });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "master-sales-target");
    this.getTerritory();
  },
  watch: {},
};
</script>
